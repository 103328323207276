import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  AlertPropsType,
  ButtonPropsType,
  ComponentConfig,
  DynamicContext,
  DynamicFormComponent,
  HttpService,
  IndependentContractorService,
} from "@lsl16/sustainability-shared-components"
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventService } from 'src/app/services/EventService.service';
import { countryList } from 'src/utilities/const/country-list';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SupplierHubService } from 'src/app/services/supplierHub/supplier-hub.service';
import { RiskassessmentService } from 'src/app/services/riskassessment/riskassessment.service';
import { BvRaRequestMessagePopUpComponent } from '../bv-ra-request-message-pop-up/bv-ra-request-message-pop-up.component';

@Component({
  selector: 'tsm-pop-up-onboarding',
  templateUrl: './pop-up-onboarding.component.html',
  styleUrls: ['./pop-up-onboarding.component.sass']
})
export class PopUpOnboardingComponent implements OnInit {
  riskAssessmentData = [];

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    public ngbModalService: NgbModal,
    public eventService: EventService,
    private supplierHubService: SupplierHubService,
    private riskAssessmentService: RiskassessmentService,   
    private IndependentContractorService : IndependentContractorService
  ) { }

  @ViewChild("form", { static: false }) form: DynamicFormComponent;
  @ViewChild('focusContainer') focusContainer: ElementRef;
  @Input() confirmType;
  alertProps: AlertPropsType;
  showAlert: boolean;
  countryServed: string;
  countryServedMapping: string;
  countryServedList: any = {};
  supplier: any;
  configs: Array<ComponentConfig> = [];
  model: any = {};
  context: DynamicContext;
  existingCountry: string[];
  countryCapList: any;
  pcParams: any;
  parentInfo: any;
  parent: any;
  hasParent: boolean = false;
  supplierTsmId: string;
  userType: any;
  showComponent = false;
  values: any = {};
  loading: boolean = false;
  callingComponent:String="tsm-pop-up-onboarding";
  independentContractorFlag: boolean = false;
   FasttrackFlag: boolean = false;
  continueProps: ButtonPropsType = {
    label: "Continue",
    backgroundColor: "blue",
    color: "white",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    padding: "14px 10px",
    margin: "0 0 0 2px",
    icon: "assets/icons/link-out.svg",
    iconHeight: 14,
    iconWidth: 14,
    iconPosition: "right",
    onClick: async () => {
      await this.sendOnboardingRequest();
    }
  };

  

  cancelProps: ButtonPropsType = {
    label: "Cancel",
    backgroundColor: "white",
    color: "purple",
    fontSize: 16,
    fontFamily: "Graphik-Medium",
    height: 48,
    borderRadius: 25,
    borderColor: 'gray',
    padding: "14px 5px",
    margin: "0 0 0 2px",
    onClick: async () => {
      await this.cancel();
    }
  };

  async ngOnInit() {
    this.supplier = this.confirmType.supplier;
    this.context = new DynamicContext();
    this.configs = JSON.parse(localStorage.getItem("onboardingConfigs"));
    this.configs[1].fieldset[0].onboarding = true;
    this.showComponent = true;
    this.userType = this.authService.getRole();
    this.accessbility();
    this.IndependentContractorService.selectedIC$.subscribe((valueIC)=>{
      console.log('line108');
      this.independentContractorFlag = false;
    });
  }
  ngAfterViewInit(){
    this.focusContainer.nativeElement.focus();
  }


  getCountryCapList(list) {
    let names = [];
    for (let a = 0; a < list.length; a++) {
      names.push(countryList.Data.mapping[list[a]]);
    }
    return names;
  }

  setCountryCapListIntoConfigs(configs: any, countryCapList: any) {
    configs[1].fieldset[0]['countryCapList'] = countryCapList;
  }
  
  async sendOnboardingRequest() {
    console.log("line126, entered");
    const tempValues = this.form.values();
    console.log("line 122:",tempValues.Fasttrack)
    if((tempValues.subCategory == 5002 || tempValues.subCategory == 5021) && tempValues.independentContractor == "") {
      this.independentContractorFlag = true;
      this.form.validate();//validing form whether any fields are empty in the onboarding page
      console.log("line132");
      return;
    } else{
      this.independentContractorFlag = false;
    }
     if((tempValues.category === 22 ) ) {
      
      this.FasttrackFlag = true;
      } else{
        this.FasttrackFlag = false;
      }
    if (this.form.validate()) {
      this.values = this.form.values();
    }
    else{
      console.log("line147");
      return;
    } 
    let supIdAndCountry = {
      supplierTsmId: (this.hasParent ? this.parentInfo.parentTsmId : this.supplier.supplierTsmId),
      countryServed: this.values.countryServed.join(',')
    };
    this.countryServedList = this.values.countryServed;
    let countryServed = this.countryServedList ? this.countryServedList : this.supplier.countryServed[0];
    let raAvailibityConst = this.values;
    raAvailibityConst.independentContractor = raAvailibityConst.independentContractor === '' ? 'null' :raAvailibityConst.independentContractor
    raAvailibityConst.supplier_TsmId = (this.hasParent ? this.parentInfo.parentTsmId : this.supplier.supplierTsmId);
    this.loading = true;
    const riskAssessmentData = await this.riskAssessmentService.getRiskAssessmentAvailability(raAvailibityConst);

    this.activeModal.close();
    this.loading = false;
    if(!riskAssessmentData['statusCode']) {
      // open alert popup for message
      this.openRAReqestPopup()
      return
    }
    if (!countryServed) {
      this.showAlert = true;
      this.alertProps = {
        type: "alert-requestVerifiedFaild",
        message: "Please kindly add at least one new country served before you send any invitation request.",
        icon: "assets/images/icon-error.svg",
        iconFill: "#cc1e32",
        iconClose: "assets/images/icon-cross-blue.svg",
        onDismiss: this.onAlertDismiss
      };
      window.scrollTo(0, 0);
      return;
    }
    this.showAlert = false;
    // transfer country served code for adapting supplier hub
    this.countryServedMapping = this.getSupplierCountryCodeFromTsmCountryCode(countryServed);
    const companyLegalName = this.supplier.companyLegalName;
    if(this.values.Fasttrack === ""){this.values.Fasttrack = false}
    const createSupplierRequestData = {
      supplierTsmId: this.supplier.supplierTsmId,
      supplierName: this.supplier.supplierName,
      countryServed: this.countryServedMapping,
      countryLocation: this.supplier.countryLocation,
      companyWebsite: this.supplier.companyWebsite,
      dunsNumber: this.supplier.dunsNumber,
      createdBy: this.authService.getLoginUser().email,
      companyLegalName: companyLegalName ? companyLegalName : "",
      category: this.values.category,
      subCategory: this.values.subCategory, 
      independentContractor: this.values.independentContractor == 'null' ? null : this.values.independentContractor,
      mixFasttrack: this.FasttrackFlag ?  this.values.Fasttrack : null,
      raId: riskAssessmentData["raId"]

    };
     console.log(createSupplierRequestData )
   
    try {
      const redirectUrl = await this.supplierHubService.getOnboardingRequestRedirectUrl(
        createSupplierRequestData
      );
      window.open(redirectUrl, "_blank");
    } catch (error) {
    }
    this.eventService.eventEmit.emit("sendOnboarding =" + this.supplier.supplierTsmId);
  };

  getSupplierCountryCodeFromTsmCountryCode(tsmCountryCodeString) {
    let countryCodeList = tsmCountryCodeString;
    let tsmCountryCodeList = "";
    for (let i = 0; i < countryCodeList.length; i++) {
      if (i === 0) {
        tsmCountryCodeList = countryList['Data']?.transferToSupplierHub[countryCodeList[i]];
      } else {
        tsmCountryCodeList = tsmCountryCodeList + "," + countryList['Data']?.transferToSupplierHub[countryCodeList[i]];
      }
    }
    return tsmCountryCodeList;
  };

  onAlertDismiss = (): void => {
    this.showAlert = false;
  };

  async cancel(): Promise<any> {
    this.activeModal.close();
  }

  accessbility() {
    const tsmRoot = document.getElementsByTagName("tsm-root")[0] as HTMLElement;
    if (tsmRoot) {
      tsmRoot.setAttribute("aria-hidden", "false");
    }
  }

  openRAReqestPopup = async () => {
    const confirmType = {
      options: {},
      centered: true,
      size: "lg",
      supplier: this.supplier,
      windowClass: "modal-onboarding"
    }
    const modalReference = this.ngbModalService.open(BvRaRequestMessagePopUpComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
  }
}
