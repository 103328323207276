import { Component, Input, OnInit } from '@angular/core';
import { BaseSupplierFilterComponent } from 'src/app/components/base-filter/base-supplier-filter.component';
import { FilterData, FilterType, fromToDateFilter, ReportType } from 'src/app/services/esgReport/esg-report.service';

@Component({
  selector: 'tsm-sa-retriggering-search',
  templateUrl: './sa-retriggering-search.component.html',
  styleUrls: ['./sa-retriggering-search.component.sass']
})
export class SaRetriggeringSearchComponent extends BaseSupplierFilterComponent implements OnInit {
  
  private filtersToApply: FilterType[] = ['marketUnit', 'countryServed', 'companyCategory', 'companySize','companySubCategory','supplierScore','esgStatus', 'supplierCategory', 'saRequestDate', 'supplierTsmId', 'lastReminderSent'];
  private dateTypes: fromToDateFilter[] = [{ from: 'esgSubmissionDateFrom', to: 'esgSubmissionDateTo' },
  { from: 'requestDateFrom', to: 'requestDateTo' }]
  reportType: ReportType = "supplier_records";
  requestType: any;

  private fieldErrors: { [key: string]: boolean } = {
    marketUnit: false,
    countryServed: false,
    companySize: false,
    companyCategory: false,
    esgStatus: false,
    companySubCategory: false,
    supplierScore: false,
    supplierCategory: false,
    saRequestDate : false
  };

  selectedOption: string = 'retrigger';
  saRetriggerFilterScenario = '';
  hasDropdownError: boolean = false;
  switchingOptions: boolean = false;
  constructor() {
    super()
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initButtons();
    this.initFilterCriteria();
  }

  initFilterCriteria() {
    this.filterCriteria = {
      marketUnit: null,
      countryServed: null,
      companyCategory: null,
      companySize: null,
      esgStatus: null,
      companySubCategory: null,
      supplierScore: null,
      supplierCategory: null,
      saRequestDate: null
    };
    this.filterCriteriaPayload = {
      marketUnit: null,
      countryServed: null,
      companyCategory: null,
      esgStatus: null,
      companySize: null,
      companySubCategory: null,
      supplierScore: null,
      supplierCategory: null,
      saRequestDate: null
    }
  }

  initButtons() {
    this.clearFilterButton = {
      label: "",
      color: "purple",
      clickBorderColor: "purple",
      backgroundColor: "lightPurple",
      hoverBorderColor: "purple",
      fontSize: 16,
      height: 48,
      width: 180,
      onClick: async () => {
        this.clearFilters();
      }
    };
    this.applyFilterButton = {
      label: "",
      color: "white",
      fontSize: 16,
      backgroundColor: "blue",
      borderColor: "blue",
      height: 48,
      width: 180,
      disabled: this.hasDropdownError,
      onClick: async () => {
        this.applyFilters();
      }
    };
  }

  applyFilters() {
    this.switchingOptions = false;
    this.invalidFields = [];
    this.filterCriteriaPayload = { ...this.filterCriteria };
    this.dateTypes.forEach((filter) => {
      this.validateDates(filter);
      this.formatPayloadDate(filter);
    });

    if (Object.keys(this.invalidFields).length > 0) {
      return
    }
    const filterData: FilterData[] = this.filtersToApply.filter(filter => this.filterCriteriaPayload[filter])
      .map(filter => ({
        filterName: filter,
        filterValue: this.filterCriteriaPayload[filter]
      }));
    this.setFilterButtonsDisabledProps(false);
    this.applyFilterEventEmitter.emit({ reportType: this.reportType, requestType: this.selectedOption, filters: filterData });
    this.scheduleWaitingMessage();
  }

  get filterMode(): string {
    return this.selectedOption === 'reminder' ? 'saReminder' : 'saRetrigger';
  }

  clearFilters() {
    this.initFilterCriteria();
    console.log("this.selectedOption",this.selectedOption)
    if(this.selectedOption === 'retrigger'){
      this.filterCriteriaPayload.esgStatus = ['Submitted and Scored for RFPs']
      this.filterCriteria.esgStatus = ['Submitted and Scored for RFPs']
    }
    this.clearFilter.next(true);
  }

  onSelectedOptionChange(): void {
    this.showWaitingMessage = false;
    this.clearFilters();
    this.switchingOptions = true;
  }
  
  // Update the error handler to track all fields
  handleDropdownError(field: string, hasError: boolean): void {
    this.fieldErrors[field] = hasError; // Track errors for individual fields

    // Update the overall error state
    this.hasDropdownError = Object.values(this.fieldErrors).some((error) => error);

    // Update button disabled property
    this.setDisabledProps(this.hasDropdownError);
  }
  setDisabledProps(isDisabled: boolean) {
    // this.applyFilterButton.disabled = isDisabled;
    if(isDisabled){
    this.applyFilterButton = { 
      ...this.applyFilterButton,
      disabled: true,
      backgroundColor: 'mediumGrey',
      color: 'white' }; // Trigger change detection
    }
    else {
      this.applyFilterButton = {
        ...this.applyFilterButton,
        disabled: false,
        backgroundColor: 'blue',
        isProcessing: false,
        borderColor: 'blue',
    };
    }
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {        
        this.applyFilterButton.label = multilanguageJson.body.main.SaRetriggerFilters.applyFilter;
        this.clearFilterButton.label = multilanguageJson.body.main.SaRetriggerFilters.clearFilter;
        this.waitingMessageLabel = multilanguageJson.body.main.SaRetriggerFilters.waitingMessage;
        this.saRetriggerFilterScenario = multilanguageJson.body.main.SaRetriggerFilters.saRetriggerFilterScenario;
      }
    }
  }

}
