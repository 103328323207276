import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DictionaryService } from '@lsl16/sustainability-shared-components';
import { Subject } from 'rxjs';

@Component({
  selector: 'tsm-requestor-purpose-filter',
  templateUrl: './requestor-purpose-filter.component.html',
  styleUrls: ['./requestor-purpose-filter.component.sass']
})
export class RequestorPurposeFilterComponent implements OnInit {
  @Output() valueChanged = new EventEmitter();
  @Output() dropdownError = new EventEmitter<boolean>();
  @Input() clearFilter: Subject<boolean>;

  purposes: any[];
  values: any[];

  labelTranslations = {
    controlLabel: '',
    inputPlaceholder: ''
  }

  constructor(private dictionaryService: DictionaryService) { }

  async ngOnInit(): Promise<void> {
    await this.initPurposes();
    this.clearFilter.subscribe(() => this.clear());
  }

  async initPurposes() {
    this.purposes = await this.dictionaryService.getDictionaryData('request_purpose');
    if (this.purposes.findIndex(rp => rp.Code.toLowerCase() === "onboarding") === -1) {
      this.purposes.push({ Code: "Onboarding", Text: "Onboarding" });
    }
  }

  dropdownChanged(selectedValues) {
    this.values = selectedValues;
    this.valueChanged.emit(this.values);
  }

  clear() {
    this.values = [];
  }
  onDropdownError(hasError: boolean) {
    this.dropdownError.emit(hasError);
  }

  ngAfterContentChecked() {
    if (localStorage.getItem('multiLanguage')) {
      const multilanguageJson = JSON.parse(
        localStorage.getItem('multiLanguage')
      );
      if (multilanguageJson.body != undefined) {
        this.labelTranslations = multilanguageJson.body.main.reportFilters.requestorPurpose;
      }
    }
  }
}
