<div class="single-position" #dropPanel>
    <div class="form-control d-flex result-display default-result-display" (click)="swapPanel()" (keydown.enter)="togglePanel"  tabindex="0">
        <input style="display: inline-block;" aria-label="single dropdown" type="text" [value]="selectedValue"
        class="single-drop-input default-singledrop-input" [placeholder]="placeholder"  (input)="inputText($event)" />
        <i class="icon-drop-arrow"><svg width="1em" height="1em" viewBox="4 2 16 16" class="bi bi-chevron-down"
                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
            </svg></i>
    </div>
    <div *ngIf="showPanel" class="single-drop-panel">
        <div class="single-drop-panel-cont">
            <div *ngFor="let item of dropdownItems;let i = index" class="line-drop" 
            [class.focused]="i === focusedIndex" (click)="onItemSelected($event, item)"
            tabindex="0" (keydown.enter)="onItemSelected($event, item)">
                {{item.displayText}}
            </div>
        </div>
    </div>

    <!-- Error message below the dropdown -->
    <div *ngIf="errorMessage" class="error-message" tabindex="0">
        {{ errorMessage }}
    </div>
</div>
