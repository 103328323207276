import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { environment } from "src/environments/environment";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import _ from "lodash";
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { IrqSuccessPopupComponent } from '../irq-success-popup/irq-success-popup.component';
@Component({
  selector: 'tsm-link-irq-to-onboarding',
  templateUrl: './link-irq-to-onboarding.component.html',
  styleUrl: './link-irq-to-onboarding.component.sass',
})
export class LinkIrqToOnboardingComponent {
  requestor: string;
  irqId: string;
  isReqFlag: boolean = false;
  onboardingResults: any;
  irqDetails: any;
  onboardingRequestor: any
  response: any;
  loading:boolean =false;
  baseUrl = `${environment.tsmBackendServiceURL}`;
  constructor(private route: ActivatedRoute, private router: Router, private HttpService: HttpService, private authService: AuthService, private dialog: MatDialog){}
  async ngOnInit(){
    this.loading = true;
    this.route.queryParams.subscribe(params => {
      this.irqId = params['irqId'];
    });
    if(!this.irqId)
    {
      window.alert("Invalid link.");
      this.router.navigate([`/`]);
    }
    this.irqDetails= await this.fetchIrqMetadata(this.irqId);
    if (!this.authService.isValidToken()) {
      this.authService.logout();
    }
    this.isReqFlag = await this.isRequestor(this.irqDetails);
    if(!this.isReqFlag)
    {
      window.alert("You don't have permission to view this page");
      this.router.navigate([`/`]);
    }
    this.response = await this.fetchOnboardingRecords(this.irqDetails);
    this.onboardingRequestor = this.response.data[0]?.createbuyer
     const tempOnboardingResults = this.response.data;
     this.onboardingResults = await this.mapCountryName(tempOnboardingResults);
     this.loading = false;
  }

  isRequestor(irqDetails: any): boolean {
    let userId = JSON.parse(localStorage.getItem("userInfo")).pocEmail;
    const requestorEmail = irqDetails.data.requestor_name;
    const isDraft = irqDetails.data.isdraft;
    if (requestorEmail === userId && isDraft === 'false') {
      return true
    }
    return false
  }
  async fetchIrqMetadata(irqId) {
    return await this.HttpService.PostPromise(`${this.baseUrl}/riskAssessmentData/getIrqWithId`,{ irqId: irqId });
  }
  
  mapCountryName(data: any) {
    const allCountryList = JSON.parse(localStorage.getItem('countryList')).countryGroupSaArr;
    for (let i = 0; i < data.length; i++) {
      let countryServedList = JSON.parse(data[i].setupcountry);
      let newCountryList = [];
      for (let l = 0; l < countryServedList.length; l++) {
          for (let m = 0; m < allCountryList.length; m++) {
              if (countryServedList[l] === allCountryList[m].tsmCode) {
                  newCountryList.push(allCountryList[m].countryName);
              }
          }
      }
      data[i].setupcountry = newCountryList;
    }
    return data;
  }

  async fetchOnboardingRecords(irqDetails: any) {
    const tsm_id = irqDetails.data.tsm_id;
    const countryServedList = irqDetails.data.country_served.split(',').map((item) => item.trim());
    const marketUnitList = irqDetails.data.market_unit.split(',').map((item) => item.trim());
    const category_code = irqDetails.data.category_code;
    const sub_category_code = irqDetails.data.sub_category_code;
    const payload = {
      tsm_id: tsm_id,
      marketUnitList: marketUnitList,
      countryServedList: countryServedList,
      category_code: category_code,
      sub_category_code: sub_category_code
    };
   return await this.HttpService.PostPromise(`${this.baseUrl}/riskAssessmentData/getOnboardingRecords`, payload);  
  }
  linkSupplierCode(irqId: string, supplierCode: string): Promise<any> {
    const url = `${this.baseUrl}/riskAssessmentData/linkingSupplierCode`;
    const payload = {
        irqId: irqId,
        supplierCode: supplierCode
    };

    return this.HttpService.PostPromise(url, payload)
        .catch(error => {
            console.error("Error linking supplier code:", error);
            throw error;
        });
}
updateIRQStatus(supplierCode: string, status: string): Promise<void> {
  const { bibdaStatus } = this.irqDetails.data;
  const bibdaCheck = bibdaStatus === "N/A" ? ["no"] : bibdaStatus !== null ? ["yes"] : undefined;
  console.log("bibdaStatus",bibdaStatus);
  console.log("bibdaCheck",bibdaCheck);
  const url = `${this.baseUrl}/riskAssessmentData/updateIRQStatus`;
  const payload = {
      supplierCode: supplierCode,
      status: status,
      bibdaCheck: bibdaCheck,
      onboardingRequestor: this.onboardingRequestor
  };
  return this.HttpService.PostPromise(url, payload);
}
  linkIrqToOnboarding(onboardingData: any)
  {
    const { relationshipnumber, infosecrisk, dataprivacyrisk, infoseclifecyclestatus } = this.irqDetails.data;

    const coupaCheck = [relationshipnumber, infosecrisk, dataprivacyrisk, infoseclifecyclestatus].some(value => value === null) 
    ? "in_progress" 
    : "completed";

    console.log("this.irqDetails.data bibda check", this.irqDetails.data);
    console.log("coupaCheck", coupaCheck);

    this.linkSupplierCode(this.irqId, onboardingData.suppliercode)
        .then(response => {
            console.log("Supplier update response:", response);
            const linkedCode = response.affectedRows[0]?.tsm_linking_supplier_code;
            console.log("Value of tsm_linking_supplier_code:", linkedCode);
            if (linkedCode > 0) {
            const dialogRef = this.dialog.open(IrqSuccessPopupComponent, {
                width: '300px',
                data: { message: "IRQ Attached Successfully!" }
            });

            dialogRef.afterClosed().subscribe(() => {
                console.log("Popup closed.");
                this.router.navigate(["/supplier-profile", this.irqDetails.data.tsm_id], {
                });
            });
            this.updateIRQStatus(onboardingData.suppliercode, coupaCheck);
            }else{
              console.log("Supplier code linking failed");
            }
        })
        .catch(error => {
            console.error("Failed to link supplier code:", error);
        });
  }
}
