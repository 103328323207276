<div class="container-fluid mb-5">
  <div class="main_bg">  
      <div class="form me-5 mb-5 ms-5 p-5">
        <div class="container container-fluid first-container d-flex">
          <div>
            <input
              class="radio-input"
              type="radio"
              id="retrigger"
              name="retriggerOption"
              [(ngModel)]="selectedOption"
              (ngModelChange)="onSelectedOptionChange()"
              value="retrigger"
              checked
            />
            <label class="input-label" for="retrigger">Retrigger Sustainability Assessment</label>
            
            <input
              class="radio-input"
              type="radio"
              id="reminder"
              name="retriggerOption"
              [(ngModel)]="selectedOption"
              (ngModelChange)="onSelectedOptionChange()"
              value="reminder"
            />
            <label class="input-label" for="reminder">Send Reminder</label>
          </div>
        <div class="advanced-search advanced-search-content">
          <div class="search-panel bg-white mb-3 mx-0">
            <div class="filters-area row g-3" style="margin-top: 30px;">
              <div class="col-4 px-16" id="mkunit">
                <tsm-market-unit-filter [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'marketUnit')"
                  (dropdownError)="handleDropdownError('marketUnit', $event)">
                </tsm-market-unit-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-country-served-filter [clearFilter]="clearFilter" (valueChanged)="
                    filterCriteriaChanged($event, 'countryServed')"
                  (dropdownError)="handleDropdownError('countryServed', $event)">
                </tsm-country-served-filter>
              </div>
              <div class="col-4 px-16" *ngIf="selectedOption === 'reminder'">
                <tsm-tsm-id [clearFilter]="clearFilter" (valueChanged)="
                    filterCriteriaChanged($event, 'supplierTsmId')">
                </tsm-tsm-id>
              </div>
              <div class="col-4 px-16" *ngIf="selectedOption === 'retrigger'">
                <tsm-company-size-filter [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'companySize')"
                  (dropdownError)="handleDropdownError('companySize', $event)">
                </tsm-company-size-filter>
              </div>
              <div class="col-4 px-16" *ngIf="selectedOption === 'retrigger'">
                <tsm-company-category-filter [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'companyCategory')"
                  (dropdownError)="handleDropdownError('companyCategory', $event)">
                </tsm-company-category-filter>
              </div>
              <div class="col-4 px-16" *ngIf="selectedOption === 'retrigger'">
                <tsm-company-subcategory-filter [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'companySubCategory')"
                  (dropdownError)="handleDropdownError('companySubCategory', $event)">
                </tsm-company-subcategory-filter>
              </div>
              <div class="col-4 px-16" *ngIf="selectedOption === 'retrigger'">
                <tsm-supplier-score-filter [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'supplierScore')"
                  (dropdownError)="handleDropdownError('supplierScore', $event)">
                </tsm-supplier-score-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-esg-status-filter *ngIf="selectedOption === 'retrigger'"
                  (valueChanged)="filterCriteriaChanged($event, 'esgStatus')"
                  (dropdownError)="handleDropdownError('esgStatus', $event)"
                  [filterMode]="filterMode">
                </tsm-esg-status-filter>
                <tsm-esg-status-filter *ngIf="selectedOption === 'reminder'"
                  [clearFilter]="clearFilter"
                  (valueChanged)="filterCriteriaChanged($event, 'esgStatus')"
                  (dropdownError)="handleDropdownError('esgStatus', $event)"
                  [filterMode]="filterMode">
                </tsm-esg-status-filter>
                <div tabindex="0" class="error" *ngIf="selectedOption === 'retrigger'">SA can be retriggered for validated assessments only</div>
              </div>
              <div class="col-4 px-16">
                <tsm-supplier-category-filter [clearFilter]="clearFilter" (valueChanged)="
                    filterCriteriaChanged($event, 'supplierCategory')
                  " (dropdownError)="handleDropdownError('supplierCategory', $event)">
                </tsm-supplier-category-filter>
              </div>
              <div class="col-4 px-16">
                <tsm-sa-request-date-filter [clearFilter]="clearFilter" [invalidValue]="invalidValue" (dateFromChanged)="
                    filterCriteriaChanged($event, 'saRequestDate')"
                (dropdownError)="handleDropdownError('saRequestDate', $event)"></tsm-sa-request-date-filter>
              </div>
              <div class="col-4 px-16" *ngIf="selectedOption === 'reminder'">
                <tsm-sa-request-trigger-date-filter [clearFilter]="clearFilter" [invalidValue]="invalidValue" (dateFromChanged)="
                    filterCriteriaChanged($event, 'lastReminderSent')"
                (dropdownError)="handleDropdownError('lastReminderSent', $event)"></tsm-sa-request-trigger-date-filter>
              </div>
              <div class="inline-block"></div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="align-self-end me-3">
                <sh-button [props]="clearFilterButton"></sh-button>
              </div>
              <div class="align-self-end">
                <sh-button [props]="applyFilterButton"></sh-button>
              </div>
            </div>
            <div id="waitingMessage" class="d-flex justify-content-end waitingMessage"
              *ngIf="showWaitingMessage && !filterRequestCompleted && !switchingOptions">
              {{ waitingMessageLabel }}
            </div>

            <div *ngIf="selectedOption === 'retrigger'" id="waitingMessage" class="d-flex justify-content-end waitingMessage set-position" tabindex="0">
              {{saRetriggerFilterScenario}} 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-5">
      <tsm-sa-retriggering-preview [applyFilterEvent]="applyFilterEventEmitter"
      [selectedOption]="selectedOption" [switchingOptions]="switchingOptions" (requestStatusEventEmitter)="handleRequestStatusEvent($event)"></tsm-sa-retriggering-preview>

    </div>
  </div>
</div>